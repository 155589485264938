tindie.reviews = (function(t, $) {
	t.showOnlyReviews = function(star_count) {
		var selectedCount = $('.review-breakdown[data-review-total="' + star_count + '"]'),
			ratingWrapper = selectedCount.closest('.star-rating')
		toggleReview = selectedCount.hasClass('active');
		$('.review-breakdown').removeClass('active')
		$('.review_wrapper .review').removeClass('hide');
		if ($('#show-all-reviews').length > 0) {
			$('#show-all-reviews').hide()
		}
		if (toggleReview == false) {
			selectedCount.addClass('active');
			ratingWrapper.addClass('filtered');
			$('.review_wrapper .review-ratings:not(.rating-' + star_count + ')').closest('.review').addClass('hide');
		} else {
			ratingWrapper.removeClass('filtered');
		}
	};
	t.init = function() {}
	return t;
})(tindie.reviews || {}, jQuery);