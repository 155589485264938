(function ($) {
  'use strict';

  // QUANTITY INPUT CLASS DEFINITION
  // ====================

  var QuantityCounter = function (element) {
    this.element = $(element);
  };

  QuantityCounter.prototype.change = function () {
    var $this     = this.element;
    var $input    = $this.parents('.quantity-input').find('input[type="text"]');
    var q         = $input.val();
    var direction = $this.attr('data-direction');

    // very simple plugin; only handles +/- 1 with two buttons and an input next to them.
    // can easily be extended for other use cases, though.

    if (direction == "up") {
      q++;
    } else if (direction == "down") {
      if (q > 1) q--;
    }

    $input.val(q).trigger('change');
  };

  // QUANTITY INPUT PLUGIN DEFINITION
  // =====================

  var old = $.fn.quantityCounter;

  $.fn.quantityCounter = function ( option ) {
    return this.each(function () {
      var $this = $(this);
      var data  = $this.data('bs.quantity');

      if (!data) {
          $this.data('bs.quantity', (data = new QuantityCounter(this)));
      }
      if (typeof option == 'string') {
          data[option]();
      }
    });
  };

  $.fn.quantityCounter.Constructor = QuantityCounter;


  // QUANTITY INPUT NO CONFLICT
  // ===============

  $.fn.quantityCounter.noConflict = function () {
    $.fn.quantityCounter = old;
    return this;
  };


  // QUANTITY INPUT DATA-API
  // ============

  $(document).on('click', '[data-control="quantity"]', function (e) {
    e.preventDefault();
    $(this).quantityCounter('change');
  });

})(jQuery);
