tindie.notes = (function(t, $) {
	var addNoteUrl = "/products/notes/add/",
		removeNoteUrl = "/products/notes/remove/";
	t.addNote = function(productId, note_text, notes) {
		$.post(addNoteUrl, {
			product_id: productId,
			note_text: note_text
		}).done(function(data) {
			if (data.state) {
				notes.push(new t.Note(self, {
					id: data.note.id,
					text: data.note.text,
					user: data.note.user,
					date: data.note.date,
				}));
			}
		});
	};
	t.removeNote = function(productId, note, notes) {
		$.post(removeNoteUrl, {
			product_id: productId,
			note_id: note.id()
		}).done(function(data) {
			if (data.state) {
				notes.remove(note);
			}
		});
	};
	t.Note = function(view, data) {
		var self = this;
		self.id = ko.observable();
		self.text = ko.observable();
		self.user = ko.observable();
		self.date = ko.observable();
		ko.mapping.fromJS(data, {}, self);
	};
	return t;
})(tindie.notes || {}, jQuery);