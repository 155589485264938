/* global tindie, ga, ko, newrelic, Big, addToViewedUrl */

tindie.products.detail = (function(t, $) {
    t.lock = true;
    var CurrencyInfo = tindie.app.CurrencyInfo;
    var productUtil = tindie.productUtils,
        shippingRatesUrl = $('#shipping').data('shipping-rates-url'),
        shippingRequestUrl = $('#create-shipping-request').data('create-request-url'),
        saveDiscountUrl = '/promotions/save-discount/',
        product = tindie.products.view,
        reviews = tindie.reviews,
        tags = tindie.tags,
        ads = tindie.ads,
        aj = tindie.ajax,
        messaging = tindie.messaging,
        notes = tindie.notes;
    var sendImpressions = function() {
        if (window.ga && ga.create) {
            ga('send', 'event', 'Product Detail', 'Send Product Impressions', {
                nonInteraction: true,
                transport: 'beacon'
            });
        }
        if (window.fbq) {
            //fbq('track', 'ViewContent');
        }
    };
    var getShippingRates = function(view) {
        if (typeof(shippingRatesUrl) !== 'undefined') {
            var data = {
                country: view.country()
            };
            aj.ajax_update_viewmodel(shippingRatesUrl, data, view, null, function(result) {
                ko.mapping.fromJS(result, {
                    currencyInfo: {
                        update: function(options) {
                            return new CurrencyInfo(view, options.data);
                        }
                    },
                    shippingRates: {
                        create: function(options) {
                            return new ShippingRate(view, options.data);
                        }
                    }
                }, view);
                view.changeCountry(false);
                view.hasRequested(false);
            });
        }
    };
    var ProductPhoto = function() {
        var self = this;
        // indicate when lazily loaded
        // wait for zoom to start loading zoom carousel
        // load everything once zoom carousel starts
        //to know which photo to show in zoom carousel
        self.active = ko.observable(true);
        self.counter = ko.observable();
        // use both src and srcset! sizes attr should just be there, not observables
        self.gallerySrcset = ko.observable('');
        self.galleryDefaultSrc = ko.observable('');
        self.galleryDataSrcset = ko.observable('');
        self.galleryDataDefaultSrc = ko.observable('');
        self.galleryDataDefaultSrc.subscribe(function() {
            self.galleryPhoto.load(self.galleryDataDefaultSrc(), self.galleryDataSrcset(), true);
        });
        self.photoZoomDataSrcset = ko.observable('');
        self.photoZoomDataDefaultSrc = ko.observable('');
        self.photoZoomDefaultSrc = ko.observable();
        self.photoZoomSrcSet = ko.observable();
        self.loadingZoom = ko.observable(false);
        self.galleryPhoto = ko.observable().lazyLoadable();
        self.galleryPhoto.loaded.subscribe(function() {
            var img = self.galleryPhoto.image();
            if (self.gallerySrcset() == '') {
                self.gallerySrcset(img.srcset);
                self.galleryDefaultSrc(img.src);
            }
        });
        self.zoomPhoto = ko.observable().lazyLoadable();
        self.zoomPhoto.loaded.subscribe(function() {
            var img = self.zoomPhoto.image();
            self.photoZoomSrcSet(img.srcset);
            self.photoZoomDefaultSrc(img.src);
            self.loadingZoom(true);
        });
    };
    var ProductChoice = function(view, option, option_el) {
        var self = this;
        var choiceInfo = $(option_el).data('choiceInfo');
        if (choiceInfo) {
            $.each(choiceInfo, function(key, value) {
                self[key] = value;
            });
        } else {
            self.alter_price = 0;
            self.original_label = '';
        }
        self.option_value = option_el.value;
        self.label_suffix = ko.observable('');
        self.display_value = ko.computed(function() {
            if (self.original_label) {
                var value = option.value();
                return self.original_label + self.label_suffix();
            }
            return '';
        });
    };
    var ProductOption = function(view, select_el) {
        var self = this;
        self.value = ko.observable(select_el.value);
        self.alter_price = ko.observable(0);
        self.choices = ko.observableArray($(select_el).find('option').map(function() {
            return new ProductChoice(view, self, this);
        }).get());
        ko.computed(function() {
            // update the extra price label and the extra price for this
            // selection
            var value = self.value();
            var choices = self.choices();
            var choice = null;
            if (value !== '') {
                for (var i = 0; i < choices.length; i++) {
                    choice = choices[i];
                    if (choice.option_value === value) {
                        break;
                    }
                }
            }
            if (choice === null) {
                choice = {
                    alter_price: 0
                };
            }
            $.each(choices, function(index, mychoice) {
                if (mychoice.value === '') {
                    return;
                }
                var diff = mychoice.alter_price - (choice.alter_price || 0),
                    prefix_text = diff < 0 ? 'subtract' : 'add';
                mychoice.label_suffix(diff ? ' [' + prefix_text + ' $' + tindie.products.formatMoney(Math.abs(diff)) + ']' : '');
                self.alter_price(choice.alter_price || 0);
            });
        });
    };
    var PriceTier = function(view, tier, index) {
        var self = this;
        $.extend(self, tier);
        self.index = index;
    };
    var ShippingRate = function(view, data) {
        var self = this;
        ko.mapping.fromJS(data, {
            baseRate: {
                create: function(options) {
                    return ko.observable(parseFloat(options.data));
                }
            }
        }, self);
        self.hasTracking = ko.computed(function() {
            return self.tracked() ? 'Yes' : 'No';
        }, self);
    };
    var ProductDetail = function(vm) {
        var self = this;
        product.call(self);
        ads.initAd(574, 1);
        $.extend(self, $('#price').data('priceInfo'));
        self.hasBeenReviewed = ko.observable(false);
        self.productID = ko.observable();
        self.productTitle = ko.observable();
        self.productStore = ko.observable();
        self.productSeller = ko.observable();
        self.productCategory = ko.observable();
        self.shippingRates = ko.observableArray([]);
        self.quantity = ko.observable(1);
        self.changeCountry = ko.observable(false).toggleable();
        getShippingRates(self);
        self.country.subscribe(function() {
            if (self.hasCountry()) {
                getShippingRates(self);
            }
        });
        self.hasShippingRates = ko.computed(function() {
            return (self.hasCountry() && self.shippingRates().length > 0);
        });
        self.cantShip = ko.computed(function() {
            return (self.hasCountry() && self.shippingRates().length == 0);
        });
        self.hasRequested = ko.observable(false);
        self.sortedShippingRates = ko.computed(function() {
            var sortedRates = self.shippingRates().sort(function(left, right) {
                return left.baseRate() === right.baseRate() ? 0 : left.baseRate() > right.baseRate() ? 1 : -1;
            });
            return sortedRates;
        });
        self.shipsFrom = ko.computed(function() {
            var shippingCountries = [];
            for (var i = 0; i < self.sortedShippingRates().length; i++) {
                if (shippingCountries.indexOf(self.sortedShippingRates()[i].shippingCountryName()) < 0) {
                    shippingCountries.push(self.sortedShippingRates()[i].shippingCountryName());
                }
            }
            return shippingCountries.toString();
        });
        self.cheapestShipping = ko.computed(function() {
            return self.sortedShippingRates().length > 0 ? self.sortedShippingRates()[0].baseRate() : null;
        });
        self.hasFreeShipping = ko.computed(function() {
            return self.cheapestShipping() == 0;
        });
        self.selectedCountry = ko.computed(function() {
            return (self.hasShippingRates() && !self.changeCountry());
        });
        self.notifyEmail = ko.observable().extend({
            required: {
                message: 'Please enter your email address.'
            },
            email: true
        });
        self.submitNotifyEmail = function() {
            if (self.notifyEmail.isValid()) {
                var data = {
                    country: self.country(),
                    requester_email: self.notifyEmail()
                };
                aj.ajax_update_viewmodel(shippingRequestUrl, data, self, null, function(result) {
                    self.hasRequested(true);
                });
            } else {
                self.notifyEmail.valueHasMutated();
            }
        };
        self.setReviewed = function() {
            self.hasBeenReviewed(true);
            if (window.ga && ga.create) {
                ga('send', 'event', 'Product Detail Page', 'click', 'Review Tab');
            }
            return true;
        };
        self.mobileReadMore = ko.observable(false).toggleable();
        self.mobileShowVideos = ko.observable(false).toggleable();
        self.mobileShowShipping = ko.observable(false).toggleable();
        self.mobileShowVolumePricing = ko.observable(false).toggleable();
        self.mobileShowDocs = ko.observable(false).toggleable();
        self.mobileShowReviews = ko.observable(false).toggleable();
        self.mobileShowSeller = ko.observable(false).toggleable();
        self.showAllReviews = ko.observable(false).toggleable();
        self.messageForm = ko.validatedObservable(new messaging.TindieMessage(false, 'Product Detail Page'));
        self.canSendMessage = ko.computed(function() {
            return !aj.lock() && self.messageForm.isValid();
        });
        self.prepAndSendMessage = function() {
            if (self.canSendMessage()) {
                self.messageForm().message(self.messageForm().message() + ' [Sent from ' + self.productTitle() + ' Page]');
                self.messageForm().send();
            }
        };
        self.gaCheck = function() {
            if (window.ga && ga.create) {
                if (self.hasBeenReviewed()) {
                    ga('send', 'event', 'Reviews', 'click', 'Added to Cart after reviewing');
                }
                ga('ec:addProduct', {
                    id: 'P-' + self.productID(),
                    name: self.productTitle(),
                    category: self.productCategory(),
                    brand: self.productSeller(),
                    //'variant': product.variant,
                    price: self.displayPrice(),
                    quantity: self.parsedQuantity()
                });
                ga('ec:setAction', 'add');
                ga('send', 'event', 'UX', 'click', 'Add to Cart'); // Send data using an event.
            }
            if (window.fbq) {
                //fbq('track', 'AddToCart');
            }
            return true;
        };
        self.options = ko.observableArray($('#add-cart select').map(function() {
            return new ProductOption(self, this);
        }).get());
        self.priceTiers = ko.observableArray($.map($('#price-tier-table').data('tiers') || [], function(tier, index) {
            return new PriceTier(self, tier, index);
        }));
        self.parsedQuantity = ko.computed(function() {
            return parseInt(self.quantity(), 10);
        });
        self.selected_tier = ko.computed(function() {
            var quantity = self.parsedQuantity();
            var tiers = self.priceTiers();
            for (var selected = 0; selected < tiers.length; selected++) {
                var tier = tiers[selected];
                if (tier.start <= quantity && (tier.stop === null || quantity < tier.stop)) {
                    return tier;
                }
            }
            return null;
        });
        self.tier_index = ko.computed(function() {
            var tier = self.selected_tier();
            if (tier) {
                return tier.index;
            }
            return -1;
        });
        self.tier_price = ko.computed(function() {
            var tier = self.selected_tier();
            if (tier !== null) {
                return tier.price;
            }
            return self.base_price;
        });
        self.option_price = ko.computed(function() {
            return $.map(self.options(), function(option) {
                return option.alter_price();
            }).reduce(function(prev, current) {
                return prev + current;
            }, 0);
        });
        self.sub_total = ko.computed(function() {
            return Big(parseFloat(self.tier_price()) + parseFloat(self.option_price())).toFixed(2);
        });
        self.isDraft = ko.computed(function() {
            return ($('#submit-id-add_cart').length == 0 && $('#submit-id-save').length == 0);
        });
        self.isInStock = ko.computed(function() {
            return $('#submit-id-add_cart').length > 0;
        });
        self.displayPrice = ko.computed(function() {
            var sub_total = self.sub_total(),
                discounted_price = sub_total;
            if (typeof self.promotions !== 'undefined') {
                if (self.promotions.length == 0) {
                    return sub_total;
                } else {
                    var promos = self.promotions;
                    for (var i = 0; i < promos.length; i++) {
                        if (promos[i]['type'] == 'percent') {
                            discounted_price = discounted_price - sub_total * (promos[i]['amount'] / 100);
                        }
                        if (promos[i]['type'] == 'amount') {
                            discounted_price = discounted_price - promos[i]['amount'];
                        }
                    }
                }
            }
            return Big(discounted_price).toFixed(2);
        });
        self.showFx = ko.computed(function() {
            return self.currencyInfo().fxCurrency() != 'USD';
        });
        self.showRegularPrice = ko.computed(function() {
            return self.sub_total() != self.displayPrice();
        });
        self.fxPrice = ko.computed(function() {
            var fxRate = self.currencyInfo().fxRate();
            var dp = self.displayPrice();
            if (!fxRate) {
                return;
            }
            if (!self.isInStock() && !self.isDraft()) {
                dp = self.display_price;
            }
            return dp * Big(fxRate);
        });
        self.fxShipping = ko.computed(function() {
            var fxRate = self.currencyInfo().fxRate();
            if (!fxRate) {
                return;
            }
            return self.cheapestShipping() * Big(fxRate);
        });
        self.promotedDiscount = ko.observable($('.product-coupon form .code').html());
        self.applyCoupon = function() {
            $.ajax({
                dataType: 'json',
                type: 'POST',
                url: saveDiscountUrl,
                data: {
                    dc: self.promotedDiscount()
                },
                success: function(result) {
                    window.location.reload(true);
                },
                error: function(result) {
                    var err = new Error('unexpected AJAX error: ' + result);
                    if (typeof(newrelic) !== 'undefined') {
                        newrelic.noticeError(err);
                    }
                }
            });
        };
        if ($('#tag-list').length > 0) {
            self.tagInput = ko.observable();
            self.tags = ko.observableArray();
            self.recTags = ko.observableArray();
            tags.initTags(self.productID());
            self.createTag = function() {
                return new tags.Tag();
            };
            self.addTags = function() {
                var tag = self.tagInput();
                self.tagInput('');
                if (tag != '') {
                    tags.addTag(self.productID(), tag, self.tags);
                }
            };
            self.addTag = function(recTag) {
                if (recTag.name() != '') {
                    tags.addTag(self.productID(), recTag.name(), self.tags);
                }
                self.recTags.remove(recTag);
            };
            self.removeTag = function(tag) {
                tags.removeTag(self.productID(), tag, self.tags);
            };
        }
        if ($('#note-list').length > 0) {
            self.showAddNotes = ko.observable(false).toggleable();
            self.noteInput = ko.observable();
            self.notes = ko.observableArray();
            self.createNote = function() {
                return new notes.Note();
            };
            self.addNote = function() {
                var note = self.noteInput();
                self.noteInput('');
                if (note != '') {
                    notes.addNote(self.productID(), note, self.notes);
                }
            };
            self.removeNote = function(note) {
                notes.removeNote(self.productID(), note, self.notes);
            };
        }
        self.show5StarReviews = function() {
            reviews.showOnlyReviews(5);
        };
        self.show4StarReviews = function() {
            reviews.showOnlyReviews(4);
        };
        self.show3StarReviews = function() {
            reviews.showOnlyReviews(3);
        };
        self.show2StarReviews = function() {
            reviews.showOnlyReviews(2);
        };
        self.show1StarReviews = function() {
            reviews.showOnlyReviews(1);
        };
        self.relatedProducts = ko.observableArray();
        self.complementaryProducts = ko.observableArray();
        self.sellerProducts = ko.observableArray();
        self.createRelatedProduct = function() {
            return productUtil.createProductCard('Related Products', self.relatedProducts);
        };
        self.createComplementaryProduct = function() {
            return productUtil.createProductCard('Complementary Products', self.complementaryProducts);
        };
        self.createSellerProduct = function() {
            return productUtil.createProductCard('Seller Products', self.sellerProducts);
        };
        self.productPhotos = ko.observableArray();
        self.isCarouselLoaded = ko.observable(false);
        self.isZoomGalleryInit = ko.observable(false);
        self.isZoomGalleryLoaded = ko.observable(false);
        self.zoomPhotoLoadingImage = $('#product-photo-carousel').data('loading-src');
        self.isZoomGalleryLoaded.subscribe(function() {
            if (self.isZoomGalleryLoaded()) {
                $.each(self.productPhotos(), function(k, photo) {
                    photo.photoZoomDefaultSrc(self.zoomPhotoLoadingImage);
                    photo.zoomPhoto.load(photo.photoZoomDataDefaultSrc(), photo.photoZoomDataSrcset(), true);
                });
            }
        });
        self.createExistingPhoto = function(data) {
            return new ProductPhoto();
        };
        self.adjustPhotoClose = function() {
            $('#product_photo_zoom .close').css('top', Math.ceil($('#product_photo_zoom_carousel')[0].getBoundingClientRect().y) + 10 + 'px');
        };
        $('#product_photo_zoom').on('shown.bs.modal', function() {
            self.adjustPhotoClose();
        });
        self.productPhotos.subscribe(function(changes) {
            if (!self.isZoomGalleryInit() && self.productPhotos().length > 0) {
                self.isZoomGalleryInit(true);
            }
            if (!self.isCarouselLoaded() && self.productPhotos().length > 1) {
                $('#product-photo-carousel').carousel({
                    interval: false
                }).bcSwipe({
                    threshold: 50
                });
                var carousel = $('.carousel').on('slid.bs.carousel', function() {
                    $('.carousel-thumbnails a').removeClass('active').filter('[data-slide-to="' + carousel.data('bs.carousel').getItemIndex(carousel.data('bs.carousel').$element.find('.item.active')) + '"]').addClass('active');
                });
                $('#product-photo-thumb-scroller').mThumbnailScroller({
                    speed: 600,
                    type: 'click-60',
                    markup: {
                        thumbnailsContainer: '#product-photo-thumbails',
                        thumbnailContainer: 'a'
                    }
                });
                self.isCarouselLoaded(true);
            }
        }, null, 'arrayChange');
        self.viewPhoto = function(selected, event) {
            var photoToZoom = $(event.target).parents('.item'),
                counter = photoToZoom.data('count');
            if ($('#product_photo_zoom_carousel .item.active').length == 0) {
                $('#product_photo_zoom_carousel .item[data-count="' + counter + '"]').addClass('active');
            }
            self.productPhotos()[counter].active(true);
            self.isZoomGalleryLoaded(true);
            $('#product_photo_zoom').modal();
            if (self.isCarouselLoaded()) {
                $('#product_photo_zoom_carousel').carousel({
                    interval: false,
                    slide: false
                }).bcSwipe({
                    threshold: 50
                });
                $('#product_photo_zoom_carousel').carousel(counter);
                $('#product_photo_zoom_carousel').on('slid.bs.carousel', function(ev) {
                    self.adjustPhotoClose();
                });
                $('#product_photo_zoom_carousel').on('slide.bs.carousel', function(ev) {
                    var current = ev.relatedTarget.getAttribute('data-count');
                    self.productPhotos().every(function(photo) {
                        if (photo.counter() == current) {
                            photo.active(true);
                        } else {
                            photo.active(false);
                        }
                    });
                });
            }
        };
        self.copyCommandResult = function(success, observable) {
            if (success) {
                observable("Copied successfully!");
            } else {
                observable("Press Ctrl+C or Command+C to copy");
            }
        };
        self.productUrl = ko.computed(function() {
            return 'https://www.tindie.com/products/' + self.productID();
        });
        self.productUrlHelpText = ko.observable();
        self.copyProductUrlSuccess = function(event) {
            self.copyCommandResult(true, self.productUrlHelpText);
        };
        self.copyProductUrlFailure = function(event) {
            self.copyCommandResult(false, self.productUrlHelpText);
        };
        window.addEventListener('resize', function() {
            self.adjustPhotoClose();
        }, true);
        self.needsLoadingZoom = ko.computed(function() {
            return self.productPhotos().every(function(photo) {
                return (photo.active() && photo.photoZoomDefaultSrc() == self.zoomPhotoLoadingImage);
            });
        });
    };
    t.init = function() {
        $.get(addToViewedUrl);
        var vm = new ProductDetail();
        ko.applyBindings(vm, document.body);
        t.lock = false;
        if (window.ga && ga.create) {
            ga('ec:addProduct', {
                id: 'P-' + vm.productID(),
                name: vm.productTitle(),
                category: vm.productCategory(),
                brand: vm.productSeller()
                //'variant': 'black'
            });
            ga('ec:setAction', 'detail');
        }
        $('.favorite-button[data-href]').on('click', function() {
            var self = $(this);
            var is_active = self.hasClass('active');
            $.ajax({
                type: 'POST',
                url: self.data('href'),
                dataType: 'json',
                data: {
                    active: is_active ? '' : 'on'
                },
                success: function(result) {
                    if (is_active) {
                        $('.favorite-button').removeClass('active');
                        if (window.fbq) {
                            //fbq('track', 'AddToWishlist');
                        }
                    } else {
                        $('.favorite-button').addClass('active');
                    }
                },
                error: function(status, data) {}
            });
        });
        if ($('.product-details-nav').length > 0) {
            $('body').scrollspy({
                target: '#product-details-nav'
            });
            $('.product-details-nav').affix({
                offset: {
                    top: $('.product-details-nav').offset().top
                }
            });
        }
        $('#add-cart').submit(function() {
            $('#submit-id-add_cart').prop('disabled', true);
        });
        $('#add-cart_mobile').submit(function() {
            $('#submit-id-add_cart_mobile').prop('disabled', true);
        });
        $('#related_products a.product-card').on('click', function() {
            if (window.ga && ga.create) {
                var action = 'Clicked Related Item: Using Reccomendations';
                ga('send', 'event', {
                    eventCategory: 'Product Detail Page',
                    eventAction: action,
                    eventLabel: 'Q1 2018',
                    transport: 'beacon'
                });
            }
        });
        window.addEventListener('beforeunload', sendImpressions, false);
    };
    return t;
})(tindie.products.detail || {}, jQuery);