tindie.products = (function(t, $) {
	t.lock = true;
	t.formChanged = false;
	t.formatMoney = function(value) {
		return value.toFixed ? value.toFixed(2) : value;
	};
	t.view = function(vm) {
		var self = this;
		tindie.app.base.call(self);
	};
	t.getFormUnsavedMsg = function() {
		return $('body').data('form-unsaved-msg');
	};
	t.init = function() {
		var page = tindie.app.getPage();
		tindie.products[page] != undefined && tindie.products[page].init();
	}
	return t;
})(tindie.products || {}, jQuery);