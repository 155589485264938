tindie.tags = (function(t, $) {
	var addTagUrl = "/products/tags/add/",
		removeTagUrl = "/products/tags/remove/";
	t.initTags = function(productId) {
		$("#id-tag-input").autocomplete({
			hint: false
		}, [{
			source: $.fn.autocomplete.sources.hits(tags, {
				hitsPerPage: 3
			}),
			displayKey: "name",
			templates: {
				header: '<div class="tt-category">Tags</div>',
				suggestion: function(hit) {
					return hit.name;
				}
			}
		}]);
	};
	t.addTag = function(productId, tagName, tags) {
		$.post(addTagUrl, {
			product_id: productId,
			tag_name: tagName
		}).done(function(data) {
			if (data.state) {
				tags.push(new t.Tag(self, {
					name: data.tag.name,
					state: data.tag.state
				}));
			}
		});
	};
	t.removeTag = function(productId, tag, tags) {
		$.post(removeTagUrl, {
			product_id: productId,
			tag_name: tag.name
		}).done(function(data) {
			var index;
			if (data.state) {
				tags.remove(tag);
			}
		});
	};
	t.Tag = function(view, data) {
		var self = this;
		self.state = ko.observable();
		self.name = ko.observable();
		ko.mapping.fromJS(data, {}, self);
	};
	return t;
})(tindie.tags || {}, jQuery);